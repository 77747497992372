import React from "react"
import { Box } from "@chakra-ui/react"
import LandingHeader from "./LandingHeader"
import Footer from "./Footer"

function LandingLayout(props) {
  return (
    <Box>
      <LandingHeader />
      <Box as="main" mt={"112"}>
        {props.children}
      </Box>
      <Footer />
    </Box>
  )
}

export default LandingLayout
